/** @jsx jsx */
import { jsx } from 'theme-ui'

import React from 'react'
import Img from 'gatsby-image'

export default ({ data }) => (
  <div>
    <Img
      sx={{
        borderRadius: `20px`,
        mb: 5,
      }}
      alt="Cookie Tempura"
      fluid={data.heroImage.fluid}
    />
  </div>
)
