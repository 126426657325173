/** @jsx jsx */

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { jsx } from 'theme-ui'

import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'

class RootIndex extends React.Component {
  render() {
    const ReactMarkdown = require('react-markdown')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [aboutPage] = get(this, 'props.data.allContentfulPage.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle} />
        <Hero data={aboutPage.node} />

        <ReactMarkdown
          sx={{
            my: 5,
            maxWidth: `3xl`,
            mx: `auto`,
          }}
          source={aboutPage.node.body.childMarkdownRemark.rawMarkdownBody}
        />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage(
      filter: { contentful_id: { eq: "3w6SOVvswBfoEUB7ec6gMm" } }
    ) {
      edges {
        node {
          title
          heroImage {
            fluid(maxWidth: 1180, maxHeight: 480, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          body {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
`
